import React from "react";
import { Link } from "react-router-dom";
import "../App.css";

function App() {
  return (
    <div>
      <div className="sidebar">
        <div className="navigation">
          <Link to="/">HOME</Link>
        </div>
      </div>
      <div className="container desktop-container header-margin">
        <div className="scrolly">
          <div className="main">
            <div className="no-hype">
              <a href="mailto:contact@xplrspc.com">CONTACT US</a>
            </div>
          </div>
        </div>
      </div>
      <nav className="nav-bottom">
        <ul className="nav-items">
          <li>
            <Link to="/contact" className="nav-link">
              CONTACT
            </Link>
          </li>
          <li>
            <Link to="/privacy" className="nav-link">
              PRIVACY
            </Link>
          </li>
          <li>
            <Link to="/terms" className="nav-link">
              TERMS
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default App;

import React, { useState } from "react";
import jsonp from "jsonp";
import toQueryString from "to-querystring";
import { Link } from "react-router-dom";
import "./assets/form.css";

const SignUp = ({ tag = "NO TAG" }) => {
  const [emailRef, setEmail] = useState(null);

  const submit = () => {
    submitToMailChimp({
      EMAIL: emailRef.value.toLowerCase(),
      SIGNUP: tag
    });
  };

  const submitToMailChimp = data => {
    const params = toQueryString(data);
    const plain_url =
      "https://xplrspc.us10.list-manage.com/subscribe/post-json?u=0ba983d3460adfecdfb831594&amp;id=8b67829971";
    const url = plain_url + "&" + params;
    jsonp(
      url,
      {
        param: "c"
      },
      (err, data) => {
        if (err || data.result !== "success") {
          window.location = "/Y5GT6CVAK3W/thank-you";
        } else {
          window.location = `/Y5GT6CVAK3W/thank-you?email=${emailRef.value.toLowerCase()}`;
        }
      }
    );
  };

  return (
    <div>
      <div className="sidebar">
        <div className="navigation">
          <Link to="/">XPLRSPC</Link>
        </div>
      </div>
      <div className="container desktop-container header-margin">
        <div className="pw-r">
          <div className="reg-l">
            <div className="cd middle">BATCH_02:</div>
            <div className="cd middle times">
              XPLRSPC<br></br> XPSWRLD<br></br> XPNDAMRCA<br></br> TRAVEL SPACE
            </div>
            <form
              onSubmit={e => {
                e.preventDefault();
                submit();
              }}
              className="xplr-vspace-bpall-small row cstudio-component-form-field___21yL0"
            >
              <div className="col-s-12 no-gutters">
                <div className="xplr-form-item xplr-form-item--required">
                  <div className="xplr-input">
                    <input
                      ref={node => node && setEmail(node)}
                      className="xplr-input__field"
                      id="mce-EMAIL"
                      name="EMAIL"
                      placeholder=" "
                      required
                      type="email"
                    />
                    <label className="xplr-input__label">EMAIL</label>
                    <li className="xplr-form-actions xplr-form-item xplr-vspace-bpall-small col-s-12 no-gutters">
                      <button
                        className="xplr-cta xplr-cta--primary"
                        id="mc-embedded-subscribe"
                        name="subscribe"
                        type="submit"
                        value="Sign up"
                      >
                        <span className="xplr-cta__content">REGISTER</span>
                        <svg className="xplr-icon xplr-cta__icon"></svg>
                      </button>
                    </li>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <nav id="navbar" className="nav-bottom">
        <ul className="nav-items">
          <li>
            <Link to="/contact" className="nav-link">
              CONTACT
            </Link>
          </li>
          <li>
            <Link to="/privacy" className="nav-link">
              PRIVACY
            </Link>
          </li>
          <li>
            <Link to="/terms" className="nav-link">
              TERMS
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default SignUp;

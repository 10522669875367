import React from "react";
import "./App.css";
import {
  Redirect,
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import TY from "./pages/confirmed.js";
import REG from "./pages/reg.js";
import TERMS from "./pages/terms.js";
import PRIVACY from "./pages/privacy.js";
import CONTACT from "./pages/contact.js";

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

window.addEventListener("resize", () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});

function App() {
  return (
    <Router>
      <Switch>
        {/*  <Route path="/000" exact component={Home} />
        <Route path="/72R3QWdA4H" exact component={Spaces} />
        <Route path="/G73D61B8LN" exact component={CD} />
        <Route path="/H8G4FDRT0L" exact component={MU} /> */}
        <Route path="/" exact component={REG} />
        <Route path="/Y5GT6CVAK3W/thank-you" exact component={TY} />
        <Route path="/terms" exact component={TERMS} />
        <Route path="/privacy" exact component={PRIVACY} />
        <Route path="/contact" exact component={CONTACT} />
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

export default App;

import React from "react";
import { Link } from "react-router-dom";
import "../App.css";

function App() {
  return (
    <div>
      <div className="sidebar">
        <div className="navigation">
          <Link to="/">XPLRSPC</Link>
        </div>
      </div>
      <div className="container desktop-container header-margin">
        <div className="cd-l">
          <div className="cd">THANK YOU</div>
        </div>
      </div>
    </div>
  );
}

export default App;
